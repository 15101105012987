import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../../general/Tooltip';
import FormModal from '../../fetch/FormModal';
import List from '../../general/List';
import {Button, Icon} from 'antd';
import generalNoParameterFormPC from '../../fetch/generalNoParameterFormPC';
import generalFailedPC from '../../fetch/generalFailedPC';
import {Forms} from '../../../constants/propTypesDefinitions';
import {Trans, t} from '@lingui/macro';
import {createFetchFormRemove} from '../../../backend/apiCalls';
/**
 * @fero
 */

class FormsList extends React.PureComponent {
    static propTypes = {
        forms: Forms.isRequired,
        selectedFormId: PropTypes.number,
        onFormSelect: PropTypes.func.isRequired,
        reloadForms: PropTypes.func.isRequired,
    };

    static defaultProps = {
        forms: [],
    };

    render() {
        const {forms, selectedFormId, onFormSelect, reloadForms} = this.props;
        if (forms.length != null && forms.length > 0) {
            return <List
                onItemClick={(form) => {
                    onFormSelect(form.id)
                }}
                values={forms}
                selectedValueId={selectedFormId}
                useScrollIntoView={false}
            >
                {forms.map((form) => {
                    return <div key={form.id} className="d-flex flex-wrap align-items-center">
                        <span className="mr-1">{form.name}</span>
                        {selectedFormId == form.id ? [
                            <RemoveForm
                                key="remove"
                                formId={form.id}
                                onRemove={() => {
                                    onFormSelect(undefined);
                                    reloadForms();
                                }}
                            />,] : null}
                    </div>;
                })}
            </List>;
        } else {
            return <h2 className="m-3 color-disabled text-center">
                <Trans>Nemáte uložené žiadne kustomizácie.</Trans>
            </h2>;
        }
    }

}

export default FormsList;


class RemoveForm extends React.PureComponent {
    static propTypes = {
        onRemove: PropTypes.func.isRequired,
        formId: PropTypes.number.isRequired,
    };

    render() {
        const {formId, onRemove} = this.props;
        return <FormModal
            openNode={
                <Tooltip title={<Trans>Odstránenie kustomizácie</Trans>}>
                    <Button className="mx-1" size="small" type="danger" icon="close"/>
                </Tooltip>
            }
            values={{}}
            onFinishSuccessful={onRemove}
            title={<Trans>Odstránenie kustomizácie</Trans>}
            Form={generalNoParameterFormPC(
                <Trans>Chcete odstrániť kustomizáciu?</Trans>,
                createFetchFormRemove(formId),
            )}
            Response={null}
            Failed={generalFailedPC(t`Nepodarilo sa odstrániť kustomizáciu.`)}
        />;
    }
}